import { concat, kebabCase, uniqBy } from "lodash";
import { TideItemForCountry, TideItemForSeizoenWedstrijd, TideItemForSporten, TideItemForTeam, TideItemForToernooi, TideItemForWedstrijd } from "../types";
import { findGameById, getChildItemsWithTypename, getParentItemWithTypename, getSportName, getSportPath, parse } from "../utils/component";
import { generateTournamentPath } from "../utils/path";
import { soccerTemplateArray } from "../contexts/component-provider";

const DEFAULT_MINIMAL_LENGTH = 3;

export interface QsmSearchResults {
  searchResults: QsmSearchResult[];
  popularResults: QsmSearchResult[];
}

export interface QsmSearchResult {
  key?: string;
  title?: string;
  path?: string;
  type: number;
  foundInPopularSearch: boolean;
  foundInSpecificSearch: boolean;
}

export const QsmSearchResultType = {
  team: 0,
  tournament: 1,
  game: 2,
  blog: 3,
  country: 4
};

export const search = (
  query: string,
  teams: TideItemForTeam[],
  tournaments: TideItemForToernooi[],
  games: TideItemForWedstrijd[],
  countries: TideItemForCountry[],
): QsmSearchResults | undefined => {
  const teamResults = searchTeams(query, teams);
  const tournamentResults = searchTournaments(query, tournaments);
  const gameResults = uniqBy(searchGames(query, games), 'key');
  const countryResults = process.env.WEBSITE === "Voetbalreizen" ? searchCountries(query, countries) : [];
  const allResults = concat(
    teamResults,
    gameResults,
    tournamentResults,
    countryResults
  );

  const searchResults = allResults.filter((searchResult) => searchResult.foundInSpecificSearch);

  const popularResults = allResults.filter((searchResult) => searchResult.foundInPopularSearch);

  return <QsmSearchResults>{
    searchResults: searchResults,
    popularResults: popularResults
  };
};

const searchTeams = (
  query: string,
  teams: TideItemForTeam[]
): QsmSearchResult[] => {
  // only search in teams from correct catalogue
  if (!query || query.length < DEFAULT_MINIMAL_LENGTH) {
    return teams.filter((team) => team.content?.general?.qsmpopular).map((team) => {
      return <QsmSearchResult>{
        key: team?.id,
        title: team?.name,
        path: "/clubs/" + (team.content?.general?.path ?? kebabCase(team.name)) + "/",
        type: QsmSearchResultType.team,
        foundInPopularSearch: true,
        foundInSpecificSearch: false,
      };
    });
  }

  const searchTerms = query.split(' ').filter(t => t.length >= 2);
  return teams.filter(x => searchTerms.every(term => x.name.toLowerCase().includes(term.toLowerCase()))).map((team) => {
    return <QsmSearchResult>{
      key: team?.id,
      title: team?.name,
      path: "/clubs/" + (team.content?.general?.path ?? kebabCase(team.name)) + "/",
      type: QsmSearchResultType.team,
      foundInPopularSearch: team.content?.general?.popular ?? false,
      foundInSpecificSearch: true
    };
  });
};

const searchTournaments = (
  query: string,
  tournaments: TideItemForToernooi[]
): QsmSearchResult[] => {
  // only search in tournaments from correct catalogue
  if (!query || query.length < DEFAULT_MINIMAL_LENGTH) {
    return tournaments.filter((tournament) => tournament.content?.general?.popular).map((tournament) => {
      const sport = process.env.WEBSITE === "Sportreizen" ? getParentItemWithTypename("TideItemForSporten", tournament) as TideItemForSporten : null;
      return <QsmSearchResult>{
        key: tournament?.id,
        title: tournament?.name,
        path: generateTournamentPath(tournament, sport) + "/",
        type: QsmSearchResultType.tournament,
        foundInPopularSearch: true,
        foundInSpecificSearch: false,
      };
    });
  }

  const searchTerms = query.split(' ');
  return tournaments.filter(x => searchTerms.every(term => x.name.toLowerCase().includes(term.toLowerCase()))).map((tournament) => {
    const sport = process.env.WEBSITE === "Sportreizen" ? getParentItemWithTypename("TideItemForSporten", tournament) as TideItemForSporten : null;
    return <QsmSearchResult>{
      key: tournament?.id,
      title: tournament?.name,
      path: generateTournamentPath(tournament, sport) + "/",
      type: QsmSearchResultType.tournament,
      foundInPopularSearch: tournament.content?.general?.popular ?? false,
      foundInSpecificSearch: true
    };
  });
};

const searchGames = (
  query: string,
  games: TideItemForWedstrijd[]
): QsmSearchResult[] => {
  // only search in games from correct catalogue
  const filteredGames = process.env.WEBSITE === "Sportreizen" ?
    games.filter(game => getSportName(game) && !soccerTemplateArray.includes(getSportName(game).toLowerCase())) :
    games.filter(game => getSportName(game) && soccerTemplateArray.includes(getSportName(game).toLowerCase()));
  const filteredSeasonGames = filteredGames.flatMap(game => getChildItemsWithTypename("TideItemForSeizoenWedstrijd", game) as TideItemForSeizoenWedstrijd[]);
  if (!query || query.length < DEFAULT_MINIMAL_LENGTH) {
    return filteredSeasonGames.filter((sg) => sg.content?.general?.popular).map((sg) => {
      const game = findGameById(filteredGames, parse(sg.content?.general?.id));
      const rootPath = process.env.WEBSITE === "Sportreizen" && game ? getSportPath(game) : "";
      return <QsmSearchResult>{
        key: game?.id,
        title: game?.name,
        path: rootPath + "/wedstrijden/" + (game?.content?.general?.path ?? kebabCase(game?.name)) + "/",
        type: QsmSearchResultType.game,
        foundInPopularSearch: true,
        foundInSpecificSearch: false,
      };
    });
  }

  const searchTerms = query.split(' ').filter(t => t.length >= 2);
  return filteredGames.filter(x => searchTerms.every(term => x.name.toLowerCase().includes(term.toLowerCase()))).flatMap((game) => {
    const seasonGames = getChildItemsWithTypename("TideItemForSeizoenWedstrijd", game) as TideItemForSeizoenWedstrijd[];
    return seasonGames.map(sg => {
      let rootPath = process.env.WEBSITE === "Sportreizen" && game ? getSportPath(game) : "";
      return <QsmSearchResult>{
        key: game.id,
        title: game.name,
        path: rootPath + "/wedstrijden/" + (game?.content?.general?.path ?? kebabCase(game?.name)) + "/",
        type: QsmSearchResultType.game,
        foundInPopularSearch: sg.content?.general?.popular ?? false,
        foundInSpecificSearch: true
      };
    });
  });
};

const searchCountries = (
  query: string,
  countries: TideItemForCountry[]
): QsmSearchResult[] => {
  if (!query || query.length < DEFAULT_MINIMAL_LENGTH) {
    return countries.filter((country) => country.content?.general?.popular).map((country) => {
      return <QsmSearchResult>{
        key: country?.id,
        title: country?.content?.general?.title,
        path: country?.content?.general?.path + "/",
        type: QsmSearchResultType.country,
        foundInPopularSearch: true,
        foundInSpecificSearch: false,
      };
    });
  }

  const searchTerms = query.split(' ').filter(t => t.length >= 2);
  return countries.filter(x => searchTerms.every(term => x.content?.general?.title?.toLowerCase().includes(term.toLowerCase()))).map((country) => {
    return <QsmSearchResult>{
      key: country?.id,
      title: country?.content?.general?.title,
      path: country?.content?.general?.path + "/",
      type: QsmSearchResultType.country,
      foundInPopularSearch: country.content?.general?.popular ?? false,
      foundInSpecificSearch: true
    };
  });
};
