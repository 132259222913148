import { RefObject, useEffect } from "react";

/**
 * Hook to handle clicks/touch events outside a reference element.
 * Source: https://usehooks.com/useOnClickOutside/
 * @param ref React ref object
 * @param handler Eventhandler to run when click or touch outside ref element occurs
 */
function useOnClickOutside(ref: RefObject<HTMLElement>, handler: (event: Event) => void) {
  useEffect(() => {
    if (typeof document === "undefined") {
      return;
    }

    const listener: EventListener = (event) => {
      if (!ref.current || ref.current.contains(event.target as Node | null)) {
        return;
      }
      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

export default useOnClickOutside;
