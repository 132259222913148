import { graphql } from "gatsby";
import React from "react";
import { Image, TideItemForNavigationItem, TideItemForNavigationSection, TideItemForTitleTextComponent } from "../../types";
import { getChildItemsWithTypename, parse } from "../../utils/component";
import Breadcrumbs, { Breadcrumb } from "../breadcrumb";
import Qsm from "../qsm";
import { isEmpty } from "lodash";
import Link from "../link";
import { generateRootPath } from "../../utils/path";

interface HeroProps {
  image?: Image;
  breadcrumbs?: Breadcrumb[];
  extraClass?: string;
  showQsm?: boolean;
  qsmSlimVersion?: boolean;
  page?: string;
  intro?: TideItemForTitleTextComponent;
  quickLinksSection?: TideItemForNavigationSection;
}

const Hero: React.FC<HeroProps> = ({ image, breadcrumbs, extraClass, showQsm, qsmSlimVersion, page, intro, quickLinksSection }) => {
  const quickLinkTitle = quickLinksSection?.content?.general?.title;
  const quickLinks = getChildItemsWithTypename("TideItemForNavigationItem", quickLinksSection) as TideItemForNavigationItem[];

  return (
    <header className={`header ${extraClass ?? ""} ${showQsm == true && page != "Home" ? "header--list-page-qsm" : ""}`}>
      {image && <img className="header__media" title={parse(image?.title)} src={parse(image.url)} alt={parse(image.altText)} />}
      <div className="header__container">
        {breadcrumbs && page?.toLowerCase() != "home" && <Breadcrumbs breadcrumbs={breadcrumbs} />}
        {showQsm && (
          <div className="header__panel">
            <div className="header__panel-content">
              {intro?.content?.general?.title && (
                <div className="heading-seperator">
                  <h1 className="header__panel-heading">{intro?.content?.general?.title}</h1>
                </div>
              )}
              <Qsm></Qsm>
              {quickLinks && (
                <div className="header__panel-tags">
                  {quickLinkTitle && <span className="header__panel-quicklinks-title">{quickLinkTitle}</span>}
                  {!isEmpty(quickLinks) && (
                    <ul>
                      {quickLinks?.map((quickLink, index) => (
                        <li key={`quicklink-${index}`}>
                          {quickLink.content?.general?.iconFontAwesome && <i className={parse(quickLink.content?.general?.iconFontAwesome)}></i>}
                          <Link
                            path={generateRootPath(parse(quickLink.content?.general?.path))}
                            url={parse(quickLink.content?.general?.url)}
                            title={parse(quickLink.content?.general?.title)}
                          >
                            {parse(quickLink.content?.general?.title)}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
              {intro?.content?.general?.text && !qsmSlimVersion && (
                <blockquote className="header__panel-text" dangerouslySetInnerHTML={{ __html: intro?.content?.general?.text }}></blockquote>
              )}
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Hero;

export const query = graphql`
  fragment TideItemForHeroComponentFragment on TideItemForHeroComponent {
    __typename
    id
    content {
      general {
        showQsm
      }
    }
    childItems {
      ... on TideItemForImage {
        __typename
        id
        name
        content {
          general {
            image {
              altText
              title
              url
            }
          }
        }
      }
      ... on TideItemForTitleTextComponent {
        __typename
        content {
          general {
            title
            text
          }
        }
      }
    }
  }

  fragment TideItemForQuickLinksSectionFragment on TideItemForNavigationSection {
    id
    __typename
    language
    parentNodeId
    content {
      general {
        title
      }
    }
    childItems {
      ... on TideItemForNavigationItem {
        id
        name
        __typename
        content {
          general {
            iconFontAwesome
            path
            title
            allItemsTitle
            url
          }
        }
      }
    }
  }
`;
