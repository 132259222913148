import React, { useContext } from "react";
import GlobalContext, { GlobalContextType } from "../../contexts/global-provider";
import Link from "../link";

export interface Breadcrumb {
  title: string;
  paths: Record<string, string>;
}

interface BreadcrumbsProps {
  breadcrumbs: Breadcrumb[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs }) => {
  const { language } = useContext<GlobalContextType>(GlobalContext);
  return (
    <div className="breadcrumb">
      <div className="arrow-list">
        <ul>
          {breadcrumbs.map((breadcrumb, index) => (
            <li key={index}>
              {index < breadcrumbs.length - 1 ? (
                <Link path={breadcrumb.paths[language]} title={breadcrumb.title} className="arrow-list__anchor">
                  {breadcrumb.title}
                </Link>
              ) : (
                <Link path={breadcrumb.paths[language]} title={breadcrumb.title} className="arrow-list__anchor arrow-list__anchor--active">
                  {breadcrumb.title}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Breadcrumbs;
